@import url('https://fonts.googleapis.com/css2?family=Inter');
@import url('https://fonts.cdnfonts.com/css/tasa-orbiter-display');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .input-base {
    @apply mt-1 w-full rounded-md px-3 text-sm h-12;
  }

  .input-normal {
    @apply border-gray-200 bg-[#F5F5F5] text-gray-700;
  }

  .input-error {
    @apply bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-white dark:text-red-500 dark:placeholder-red-500 dark:border-red-500;
  }

  .black-outlined-button {
    @apply font-medium py-2 px-6 rounded-full focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-opacity-50 text-black border-2 border-black hover:bg-gray-100;
  }

  .black-background-button {
    @apply font-medium py-2 px-6 rounded-full focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-opacity-50 bg-black text-white border-2 border-black hover:bg-gray-800;
  }

  @keyframes fadeUp {
    0% {
      transform: translateY(25%);
      opacity: 0;
    }
    100% {
      transform: translateY(0%);
      opacity: 1;
    }
  }

  .fade-up-25 {
    animation: 1.5s fadeUp;
  }
}

body {
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

* {
  font-family: 'Tasa Orbiter Display', sans-serif;
}

.example-container .example-pink {
  @apply bg-gray-100 text-black px-4 py-2 rounded-full text-sm font-bold;
}
